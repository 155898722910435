import cx from "classnames";

export type CardBodyProps = {
  children: React.ReactNode;
  padding?: "sm" | "md" | "none";
  minHeight?: "sm" | "md" | "lg" | "xl";
};

export function CardBody({
  children,
  padding = "md",
  minHeight,
}: CardBodyProps) {
  return (
    <>
      <div
        className={cx({
          "p-7": padding === "md",
          "p-4": padding === "sm",
          "p-0": padding === "none",
          "min-h-52": minHeight === "sm",
          "min-h-60": minHeight === "md",
          "min-h-80": minHeight === "lg",
          "min-h-96": minHeight === "xl",
        })}
      >
        {children}
      </div>
    </>
  );
}
