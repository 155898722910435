import cx from "classnames";

export type CardProps = {
  roundedClassName?: string;
  shadowClassName?: string;
  children: React.ReactNode;
};

export function Card({
  children,
  roundedClassName = "rounded-sm",
  shadowClassName = "shadow-sm",
}: CardProps) {
  return (
    <div
      className={cx(roundedClassName, shadowClassName, "bg-bg-container")}
      role="region"
    >
      {children}
    </div>
  );
}
